/* eslint-disable no-use-before-define */

export interface IjwtData {
  token: string;
  partnerId: string;
  partnerType: PartnerTypes;
  partnerName: string;
  partnerImg: string;
  businessProfileId: string;
  // ppPartnerBusinessProfileName
  businessProfile: string | null;
  businessProfileUserId: string | null;
  businessProfileUserImage: string | null;
  businessProfileUserIdAnalyticsReady: string;
  // ppPartnerAgentName
  user: string;
  // ppPartnerAgentId
  userId: string;
  agentEmail: string;
  agentId: string;
  // ppPartnerAgentImage
  img: string;
  pages: string;
  dpo: string;
  needsDpoReview: string;
  language: string;
  timezone: string;
  viewer: boolean;
  attributes: any;
  realUserId?: string | null;
}

export interface ILooseObject {
  [key: string]: any;
}

export interface IErrorObjectDataObject {
  message?: string;
}

export interface IErrorObject {
  response?: {
    data?: string | IErrorObjectDataObject;
  };
  message?: string;
}

export enum PartnerTypes {
  brand = 'BRAND',
  agency = 'AGENCY',
  influencer = 'INFLUENCER',
}

export enum SocialNetworks {
  FACEBOOK = 'FACEBOOK',
  FACEBOOKPAGE = 'FACEBOOKPAGE',
  FACEBOOKPAGEINSTAGRAM = 'FACEBOOKPAGEINSTAGRAM',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
}

export interface ISocialNetworkAccount {
  sn: string;
  network?: string;
  profileId: string | null;
  profile?: {
    name: string;
    profilePicURL: string;
  };
  expired?: boolean | null;
  token: string;
  added: boolean;
  name: string | null;
  displayPicture: string | null;
}

export interface IRegisterPartnerSocialNetworkParams {
  name: string;
  description: string;
  mode: string;
  socialTokens: Array<ISocialNetworkAccount>;
  businessId: string | undefined;
  displayPicture: string;
  forceBpUserIdNull: boolean;
}

export interface ILoadPartnerBusinessProfileResponse {
  analyticsReady?: boolean;
  createInvitationList?: any;
  createdAt: BigInt;
  description: string;
  displayPicture: string;
  expired?: boolean;
  id: string;
  listId?: string;
  name: string;
  partnerId: string;
  ratios?: any;
  socialTokens: Array<ISocialNetworkAccount>;
  templateId?: any;
  updatedAt: BigInt;
  userId: string | null;
}

export interface IBusinessProfile {
  _id: string;
  name: string;
  description: string;
  displayPicture: string;
  socialTokens: any[];
  partnerId: string;
  userId: string;
  default?: boolean;
  topicsInterest?: string[];
  countryCode?: string;
}

export enum GENDERS {
  'MALE' = 'MALE',
  'FEMALE' = 'FEMALE',
  'OTHER' = 'OTHER',
}

export interface IGetLocationsResponseObjectEntry {
  key: string;
  value: {
    countryId: string;
    country: string;
    cities: Array<{
      cityId: string;
      city: string;
    }>;
  };
}

export type TGetLocationsResponseEntry = [
  string,
  {
    countryId: string;
    country: string;
    cities: Array<{
      cityId: string;
      city: string;
    }>;
  },
];

export type TGetLocationsResponse = [TGetLocationsResponseEntry];

export type TLocations = Map<
  string,
  {
    countryId: string;
    country: string;
    cities: Array<{ cityId: string; city: string }>;
  }
>;

export type TCountriesAvailableValues = Array<{
  value: string;
  label: string;
  data?: {
    country: string;
    countryId: string;
  };
}>;

export type TCitiesAvailableValues = Array<{
  value: string;
  label: string;
  data?: {
    city: string;
  };
}>;

export type TTopicsInterestAvailableValues = Array<{
  value: string;
  label: string;
}>;

export interface IAgencyNameIdPair {
  name: string;
  id: string;
}

export type TAgenciesNameIdPairs = Array<IAgencyNameIdPair>;

export type TAgenciesNameIdPairsAvailableValues = Array<{
  value: string;
  label: string;
}>;

export type ISocialNetworkAccountFromBackend = {
  type: string;
  name: string | null;
  token: string;
  profileId: string | null;
  refreshToken: string | null;
  tokenSecret: string | null;
  displayPicture: string | null;
  screenName: string | null;
  externalUrl: string | null;
  expired: boolean | null;
  apiMode: string | null;
};

export interface ISignUpPayload {
  name: string;
  email: string;
  password?: string;
  socialTokens: ISocialNetworkAccountSignUp[] | null;
  loginTokens: ISocialNetworkAccountSignUp[] | null;
  displayPicture?: string | null;
  topicsOfInterest?: string[] | null;
  age?: number | null;
  gender?: string | null;
  country?: string | null;
  countryId?: string | null;
  countryCode?: string | null;
  city?: string | null;
  cityId?: string | null;
  partnerType: PartnerTypes | null;
  over18?: boolean | null;
  agencyTalent?: boolean | null;
  agencyId?: string | null;
  agencyName?: string | null;
  lookingForwardAgency?: boolean | null;
  brandName?: string | null;
  brandWebsite?: string | null;
  brandEmail?: string | null;
}

export type ISocialNetworkAccountSignUp = {
  type: string | null;
  name: string | null;
  token: string | null;
  profileId: string | null;
  refreshToken: string | null;
  tokenSecret: string | null;
  displayPicture: string | null;
  screenName: string | null;
  externalUrl: string | null;
  expired: boolean | null;
  apiMode?: string | null;
};

export interface ISendAccountDeletionEmailPayload {
  email: string;
}

export interface ICountryComboBoxEntry {
  value: string;
  data: {
    country: string;
    countryId: string;
  };
}

export interface ICountryComboBoxEntryBasic {
  value: string;
}

export interface IGenderComboBoxEntry {
  value: GENDERS;
  data: undefined;
}

export interface ISocialNetworkComboBoxEntry {
  value: SocialNetworks;
  data: undefined;
}

export interface ICityComboBoxEntry {
  value: string;
  data: {
    city: string;
  };
}

export type RoleIds =
  | '5bcd04f00000000000000000'
  | '5bcd04f00000000000000001'
  | '5bcd04f00000000000000002'
  | '5bcd04f00000000000000003'
  | '5bcd04f00000000000000004'
  | '60e2e076222a2e37007edc17';

export const RoleIdNames = {
  '5bcd04f00000000000000000': 'Super User',
  '5bcd04f00000000000000001': 'Partner Admin',
  '5bcd04f00000000000000002': 'Agent Admin',
  '5bcd04f00000000000000003': 'Agent Operator',
  '5bcd04f00000000000000004': 'Agent Viewer',
  '60e2e076222a2e37007edc17': 'Campaign Viewer',
};

export const RoleNames = {
  SUPER_USER: 'Super User',
  PARTNER_ADMIN: 'Partner Admin',
  AGENT_ADMIN: 'Agent Admin',
  AGENT_OPERATOR: 'Agent Operator',
  AGENT_VIEWER: 'Agent Viewer',
  CAMPAIGN_VIEWER: 'Campaign Viewer',
};

export interface IAgent {
  _id?: string;
  partnerId: string;
  name: string;
  email: string;
  active: boolean;
  interactionType: string;
  dataAccessLevelId?: string;
  roleIds?: string[];
  displayPicture?: string | null;
  external?: boolean;
  dpo?: boolean;
  externalCompany?: string | null;
  mobilePhone?: string | null;
  city?: string | null;
  cityId?: string | null;
  country?: string | null;
  countryId?: string | null;
  businessProfileId?: string | null;
  language?: string;
  sectionHelpersPopup?: {
    [key: string]: boolean;
  };
  lastLoginAt?: Date | null;
  logins?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deleted?: boolean | null;
  deletedAt?: Date | null;
}

interface IBusinessPlan {
  tier: string;
  trialDays: number;
  active: boolean;
  months: number;
  createdAt: Date;
  updatedAt: Date;
}

interface IPaywareDetails {
  payware: string;
  customerId: string;
  paymentId: string | null;
  active: boolean;
}

export enum Version {
  ZERO = 0.0,
  ONE = 1.0,
}

export interface IPartner {
  _id: string;
  name: string;
  dataAccessLevel: string;
  displayPicture?: string;
  contactDetail?: {
    companyName?: string;
    vat_id: string;
    address1: string;
    address2: string;
    postalCode: string;
    country?: string;
    countryId?: string;
    city?: string;
    cityId?: string;
    website?: string;
    manager1?: string;
    manager2?: string;
    description?: string;
    projectName?: string;
    brand?: string;
    email?: string;
  };
  billingDetail?: {
    companyName?: string;
    vat_id: string;
    vatExemption: boolean;
    address1?: string;
    address2?: string;
    postalCode?: string;
    city?: string;
    cityId?: string;
    country?: string;
    countryId?: string;
  };
  billingToContactAddress: boolean;
  needsDpoReview: boolean;
  businessPlanTier: string[];
  businessPlans: IBusinessPlan[];
  businessPlanStartAt: Date;
  businessPlanEndAt?: Date;
  businessPlanExpired: boolean;
  businessPlanTrialDays: number;
  businessPlanMonths: number;
  webSite?: string;
  paywareDetails: Array<IPaywareDetails>;
  active: boolean;
  activeCode?: string;
  activeCreatedAt?: Date;
  activeUpsertedAt?: Date;
  activeAttempts: number;
  activeByMobile?: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  isAdditionalDataStepComplete: boolean;
  version: Version;
  deleted: boolean | null;
  deletedAt?: Date | null;
}

export enum FROM_ROUTES {
  'AGENT_CREATE' = 'AGENT_CREATE',
}

export type PublicProfile = {
  publicUserId: string | null;
  username: string;
  socialNetwork: SocialNetworks;
  displayPicture?: string;
};

export type User = {
  name?: string;
  displayPicture?: string;
  gender?: string;
  countryCode?: string;
  dateOfBirth?: string;
  publicProfiles?: PublicProfile[];
  socialUsers: {
    socialUserId?: string;
  }[];
};

export type PublicUser = {
  _id: string;
  biography: string | null;
  socialNetwork: IPublicUserSocialNetwork;
  name: string;
  image: string;
  kpi: IPublicUserKpis;
  countryCode: string | null;
  gender: string | null;
  topicsInterest: string[];
  socialUserId: string | null;
};

export interface IPublicUserSocialNetwork {
  profileId: string;
  externalId: string;
  username: string;
  network: SocialNetworks;
}

export interface IPublicUserKpis {
  audience: number;
  likes?: number;
  comments?: number;
  posts?: number;
  shares?: number;
  engagement_rate?: number;
  reactions?: number;
  pulsedSkorr?: number;
  formulaSkorr?: number;
}

export const DEFAULT_USER_AVATAR =
  'https://gateway.skorr.social/public/v1/file/show/b823f73dd54577430426ba96373185.jpeg';

export enum ViewTypes {
  FULL = 'FULL',
  PUBLIC = 'PUBLIC',
}

export interface IPublicUser {
  _id: string;
  network?: SocialNetworks;
  socialNetwork?: IPublicUserSocialNetwork;
  name?: string;
  category?: PublicUserCategories;
  image?: string;
  kpi?: IPublicUserKpis;
  biography?: string | null;
  countryCode?: string | null;
  emails?: string[];
  imageExpireAt?: Date | null;
  lastScraped?: Date | null;
  userId?: string | null;
  gender?: string | null;
  scraped_cnt?: number;
  topicsInterest?: string[];
  scrapedCnt?: number;
  failedScrapeCnt?: number;
  private?: boolean;
  isNew?: boolean;
  contacts?: IPublicUserContact[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IPublicUserContact {
  type: PublicUserContactTypes;
  value: string;
}

export enum PublicUserContactTypes {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  URL = 'URL',
}

export enum PublicUserCategories {
  BRAND = 'BRAND',
  INFLUENCER = 'INFLUENCER',
  SOCIALMEDIA = 'SOCIALMEDIA',
}

export type SortOrder = 'ASC' | 'DESC';
