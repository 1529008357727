/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  GENDERS,
  ICountryComboBoxEntry,
  IGenderComboBoxEntry,
  PartnerTypes,
  TCountriesAvailableValues,
} from '../../Services/Utils/types';
import { useUpdateInfluencerProfile } from './RQCustomHooks';
import {
  useGetLocations,
  useGetSelfUser,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { Tooltip } from '../../components/shared/Tooltip/Styles';
import { valueEmpty } from '../../validations/validationFunctions';
import { IInfluencerProfileEditProps } from './types';
import { IFile } from '../../components/shared/FilePickerV2/types';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import auth from '../../api/auth-helper';
import { isAuthenticated } from '../../Services/Utils/Utils';

const InfluencerProfileEdit = (props: IInfluencerProfileEditProps) => {
  const [influencerImageSrc, setInfluencerImageSrc] = useState('');
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] =
    useState<boolean>(false);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] =
    useState<boolean>(false);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [countrySelected, setCountrySelected] = useState<
    ICountryComboBoxEntry | undefined
  >();
  const [genderSelected, setGenderSelected] = useState<
    IGenderComboBoxEntry | undefined
  >();
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const [name, setName] = useState<string>();
  const {
    isLoading: isLoadingUpdateInfluencerProfile,
    isSuccess: isSuccessUpdateInfluencerProfile,
    isError: isErrorUpdateInfluencerProfile,
    mutate: updateInfluencerProfile,
  } = useUpdateInfluencerProfile();
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const [
    showInfluencerProfileUpdateSuccessNotification,
    setShowInfluencerProfileUpdateSuccessNotification,
  ] = useState<boolean>(false);
  const [
    showInfluencerProfileUpdateSuccessNotificationText,
    setShowInfluencerProfileUpdateSuccessNotificationText,
  ] = useState<boolean>(false);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const initialInfluencerImageUrl = useMemo(
    () => [influencerImageSrc],
    [influencerImageSrc],
  );
  const [gendersAvailableValues, _] = useState([
    {
      value: GENDERS.MALE,
      label: props.t('userProfile.male'),
    },
    {
      value: GENDERS.FEMALE,
      label: props.t('userProfile.female'),
    },
    {
      value: GENDERS.OTHER,
      label: props.t('userProfile.other'),
    },
  ]);
  const [yearOfBirth, setYearOfBirth] = useState();
  const [monthOfBirth, setMonthOfBirth] = useState();
  const [dayOfBirth, setDayOfBirth] = useState();
  const monthOfBirthRef = useRef<HTMLInputElement>();
  const dayOfBirthRef = useRef<HTMLInputElement>();
  const [yearOfBirthInputFocused, setYearOfBirthInputFocused] = useState(false);
  const [monthOfBirthInputFocused, setMonthOfBirthInputFocused] =
    useState(false);

  const saveButtonClicked = () => {
    const args: any = {};
    args.name = name;
    args.displayPicture = influencerImageSrc;
    if (
      String(yearOfBirth ?? '').length === 4 &&
      String(monthOfBirth ?? '').length >= 1 &&
      String(dayOfBirth ?? '').length >= 1
    ) {
      args.dateOfBirth = new Date(
        `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
      );
    } else {
      args.dateOfBirth = null;
    }
    args.countryId = countrySelected?.data.countryId ?? null;
    args.countryCode = countrySelected?.value ?? null;
    args.gender = genderSelected ?? null;
    let influencerProfilePictureFormData;
    if (files[0]?.data) {
      influencerProfilePictureFormData = new FormData();
      influencerProfilePictureFormData.append('file', files[0]!.data);
      influencerProfilePictureFormData.append('source', 'GENERIC');
    }
    updateInfluencerProfile({
      influencerProfileUpdateData: args,
      influencerPictureData: influencerProfilePictureFormData,
    });
  };

  useEffect(() => {
    if (auth.isAuthenticated().partnerType === PartnerTypes.influencer) {
      setIsGetSelfUserEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetSelfUser) {
      setName(selfUser.name ?? '');
      if (selfUser.displayPicture) {
        setInfluencerImageSrc(selfUser.displayPicture);
      }
      if (selfUser.dateOfBirth) {
        const dateOfBirthObj = new Date(selfUser.dateOfBirth);
        setYearOfBirth(String(dateOfBirthObj.getFullYear()) as any);
        setMonthOfBirth(String(dateOfBirthObj.getMonth() + 1) as any);
        setDayOfBirth(String(dateOfBirthObj.getDate()) as any);
      }
      setIsGetSelfUserEnabled(false);
    }
  }, [isSuccessGetSelfUser]);

  useEffect(() => {
    if (locations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [isSuccessGetLocations]);

  useEffect(() => {
    if (selfUser) {
      if (countriesAvailableValues.length) {
        if (selfUser.countryCode) {
          for (let i = 0; i < countriesAvailableValues.length; i++) {
            if (countriesAvailableValues[i].value === selfUser.countryCode) {
              setCountrySelected({
                value: selfUser!.countryCode!,
                data: countriesAvailableValues[i].data!,
              });
              break;
            }
          }
        }
      }

      if (gendersAvailableValues.length) {
        if (selfUser.gender) {
          for (let i = 0; i < gendersAvailableValues.length; i++) {
            if (
              gendersAvailableValues[i].value === selfUser.gender.toUpperCase()
            ) {
              setGenderSelected({
                value: selfUser.gender.toUpperCase() as GENDERS,
                data: undefined,
              });
              break;
            }
          }
        }
      }
    }
  }, [selfUser, countriesAvailableValues, gendersAvailableValues]);

  useEffect(() => {
    if (
      yearOfBirthInputFocused &&
      !Number.isNaN(yearOfBirth) &&
      String(yearOfBirth).length === 4
    ) {
      monthOfBirthRef.current?.focus();
    }
  }, [yearOfBirth]);

  useEffect(() => {
    if (
      monthOfBirthInputFocused &&
      !Number.isNaN(monthOfBirth) &&
      String(monthOfBirth).length === 2
    ) {
      dayOfBirthRef.current?.focus();
    }
  }, [monthOfBirth]);

  useEffect(() => {
    if (isSuccessUpdateInfluencerProfile) {
      setShowInfluencerProfileUpdateSuccessNotification(true);
      setShowInfluencerProfileUpdateSuccessNotificationText(true);
      setTimeout(() => {
        setShowInfluencerProfileUpdateSuccessNotification(false);
      }, 2000);
      setTimeout(() => {
        setShowInfluencerProfileUpdateSuccessNotificationText(false);
      }, 2600);
    }
  }, [isSuccessUpdateInfluencerProfile]);

  useEffect(() => {
    if (isErrorGetSelfUser) {
      setHasError(true);
      setMsgError(props.t('influencerProfile.error-get-user-data'));
      setIsGetSelfUserEnabled(false);
    }
  }, [isErrorGetSelfUser]);

  useEffect(() => {
    if (isErrorGetLocations) {
      setHasError(true);
      setIsGetLocationsEnabled(false);
      setMsgError(props.t('generic.couldNotGetCountries'));
    }
  }, [isErrorGetLocations]);

  useEffect(() => {
    if (isErrorUpdateInfluencerProfile) {
      setHasError(true);
      setMsgError(props.t('influencerProfile.couldNotInfluencerProfile'));
    }
  }, [isErrorUpdateInfluencerProfile]);

  const cancelButtonClicked = () => {
    navigate(-1);
  };

  const getLoaderMessage = () => {
    if (isFetchingGetSelfUser) {
      return props.t('influencerProfile.loading-influencer-profile-data');
    }
    if (isLoadingUpdateInfluencerProfile) {
      return props.t('influencerProfile.savingChanges');
    }
    if (isFetchingGetLocations) {
      return props.t('influencerProfile.loading-locations-data');
    }
    return '';
  };

  const isSaveButtonEnabled = () => {
    if (!valueEmpty(name)) return true;
    return false;
  };

  const getActionResultTooltipMessage = () => {
    if (showInfluencerProfileUpdateSuccessNotificationText)
      return props.t('influencerProfile.profileUpdated');
  };

  const errorModalCloseButtonClicked = () => {
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }
    setHasError(false);
  };

  const getPageTitle = () => {
    return props.t('influencerProfile.editProfile');
  };

  if (isAuthenticated()?.partnerType !== PartnerTypes.influencer) {
    return null;
  }

  return (
    <S.OuterContainer>
      <Loader
        show={
          isFetchingGetSelfUser ||
          isFetchingGetLocations ||
          isLoadingUpdateInfluencerProfile
        }
        message={getLoaderMessage()}
      />

      <S.Container>
        <S.InnerContainer>
          <S.BusinessProfileCard>
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderBackArrowContainer
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <BackArrowIcon width={'4rem'} height={'4rem'} />
                </S.HeaderBackArrowContainer>
                <S.HeaderTitleContainer>
                  {getPageTitle()}
                </S.HeaderTitleContainer>
                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            <S.CardInnerContainer>
              <S.BPDetailsContainer>
                <S.Title>{props.t('influencerProfile.profileDetails')}</S.Title>

                <S.BPDetailsInnerContainer>
                  <S.BPDetailsInnerFirstRowContainer>
                    <S.BPImagePickerContainer>
                      <S.BPImagePickerButtonContainer>
                        <FilePickerV2
                          handleChange={(files: IFile[]) => setFiles(files)}
                          text={props.t('additionalDataStep.selectPicture')}
                          showSelectedFiles={false}
                          single={true}
                          type={'images'}
                          marginTop={'1rem'}
                          initialImages={initialInfluencerImageUrl}
                          imageContainerWidth={'16.8rem'}
                          imageContainerHeight={'13.8rem'}
                        />
                      </S.BPImagePickerButtonContainer>
                    </S.BPImagePickerContainer>

                    <S.BPNameAndDescriptionContainer>
                      <S.BPNameAndDescriptionInnerContainer>
                        <InputControlV2
                          value={name}
                          onChange={(inputValue: string) => setName(inputValue)}
                          title={props.t('generic.name')}
                          inputFieldContainerWithTitleWidth={'100%'}
                          placeholder={props.t('influencerProfile.insertName')}
                          inputTitleMarginBottom={'1.2rem'}
                          inputFieldContainerMarginBottom={'2.7rem'}
                        />

                        <S.CountryGenderContainer>
                          <S.CountryContainer>
                            <S.TopicsInterestTitleContainer>
                              {props.t('generic.country')}
                            </S.TopicsInterestTitleContainer>

                            <ComboBox
                              valueKey={'countrySelected'}
                              value={countrySelected}
                              type={'single'}
                              width={'100%'}
                              borderRadius={'0.6rem'}
                              valuesAvailable={countriesAvailableValues}
                              isFilterable={true}
                              isClearable={false}
                              onChange={(obj: {
                                countrySelected: ICountryComboBoxEntry;
                              }) => {
                                setCountrySelected(obj.countrySelected);
                              }}
                              height={'4.4rem'}
                              availableOptionsContainerBorderRadius={'0.6rem'}
                              optionContainerBorderRadius={'0.6rem'}
                              innerOptionsContainerMarginTop={'1.2rem'}
                              innerOptionsContainerMarginBottom={'1.2rem'}
                              innerOptionsContainerPaddingLeft={'1.2rem'}
                              innerOptionsContainerPaddingRight={'1.2rem'}
                              optionContainerPaddingTop={'2rem'}
                              optionContainerPaddingBottom={'2rem'}
                              optionContainerPaddingLeft={'2rem'}
                              optionContainerPaddingRight={'2rem'}
                              themeStyles={
                                (themeGlobal as any).comboBoxTopicsOfInterest
                              }
                              changeBackgroundColorOnHover={false}
                              innerOptionsContainerMaxHeight={'25rem'}
                            />
                          </S.CountryContainer>

                          <S.GenderContainer>
                            <S.TopicsInterestTitleContainer>
                              {props.t('generic.gender')}
                            </S.TopicsInterestTitleContainer>

                            <ComboBox
                              valueKey={'genderSelected'}
                              value={genderSelected}
                              type={'single'}
                              width={'100%'}
                              borderRadius={'0.6rem'}
                              valuesAvailable={gendersAvailableValues}
                              isFilterable={false}
                              isClearable={false}
                              onChange={(obj: {
                                genderSelected: IGenderComboBoxEntry;
                              }) => {
                                setGenderSelected(obj.genderSelected);
                              }}
                              height={'4.4rem'}
                              availableOptionsContainerBorderRadius={'0.6rem'}
                              optionContainerBorderRadius={'0.6rem'}
                              innerOptionsContainerMarginTop={'1.2rem'}
                              innerOptionsContainerMarginBottom={'1.2rem'}
                              innerOptionsContainerPaddingLeft={'1.2rem'}
                              innerOptionsContainerPaddingRight={'1.2rem'}
                              optionContainerPaddingTop={'2rem'}
                              optionContainerPaddingBottom={'2rem'}
                              optionContainerPaddingLeft={'2rem'}
                              optionContainerPaddingRight={'2rem'}
                              themeStyles={
                                (themeGlobal as any).comboBoxTopicsOfInterest
                              }
                              changeBackgroundColorOnHover={false}
                              innerOptionsContainerMaxHeight={'25rem'}
                            />
                          </S.GenderContainer>
                        </S.CountryGenderContainer>

                        <S.GenderAgeContainer>
                          <S.DateOfBirthPickerContainer>
                            <S.DateOfBirthPickerTitle>
                              {props.t('generic.dateOfBirth')}
                            </S.DateOfBirthPickerTitle>

                            <S.DateOfBirthPickerInputContainer>
                              <InputControlV2
                                value={yearOfBirth}
                                type={'number'}
                                onChange={(inputValue: string) => {
                                  if (
                                    Number(inputValue) >= 0 &&
                                    inputValue.length <= 4
                                  ) {
                                    setYearOfBirth(inputValue as any);
                                  }
                                }}
                                placeholder={props.t(
                                  'influencerProfile.insertYearPlaceholder',
                                )}
                                inputFieldContainerWidth={'12rem'}
                                onFocus={() => setYearOfBirthInputFocused(true)}
                                onBlur={() => setYearOfBirthInputFocused(false)}
                              />
                              <S.DateOfBirthInputSeparator>
                                <S.DateOfBirthInputSeparatorContent />
                              </S.DateOfBirthInputSeparator>
                              <InputControlV2
                                value={monthOfBirth}
                                type={'number'}
                                onChange={(inputValue: string) => {
                                  if (
                                    Number(inputValue) >= 0 &&
                                    Number(inputValue) <= 12
                                  ) {
                                    setMonthOfBirth(inputValue as any);
                                  }
                                }}
                                placeholder={props.t(
                                  'influencerProfile.insertMonthPlaceholder',
                                )}
                                inputFieldContainerWidth={'10.5rem'}
                                refNotNative={monthOfBirthRef}
                                onFocus={() =>
                                  setMonthOfBirthInputFocused(true)
                                }
                                onBlur={() =>
                                  setMonthOfBirthInputFocused(false)
                                }
                              />
                              <S.DateOfBirthInputSeparator>
                                <S.DateOfBirthInputSeparatorContent />
                              </S.DateOfBirthInputSeparator>
                              <InputControlV2
                                value={dayOfBirth}
                                type={'number'}
                                onChange={(inputValue: string) => {
                                  if (
                                    Number(inputValue) >= 0 &&
                                    Number(inputValue) <= 31
                                  ) {
                                    setDayOfBirth(inputValue as any);
                                  }
                                }}
                                placeholder={props.t(
                                  'influencerProfile.insertDayPlaceholder',
                                )}
                                inputFieldContainerWidth={'10.5rem'}
                                refNotNative={dayOfBirthRef}
                              />
                            </S.DateOfBirthPickerInputContainer>
                          </S.DateOfBirthPickerContainer>
                        </S.GenderAgeContainer>
                      </S.BPNameAndDescriptionInnerContainer>
                    </S.BPNameAndDescriptionContainer>
                  </S.BPDetailsInnerFirstRowContainer>
                </S.BPDetailsInnerContainer>
              </S.BPDetailsContainer>

              <S.BusinessProfileFooter>
                <StyledButton
                  borderWidth={'0.2rem'}
                  borderRadius={'10rem'}
                  paddingLeft={'2rem'}
                  paddingTop={'0.8rem'}
                  paddingBottom={'0.8rem'}
                  paddingRight={'2rem'}
                  marginRight={'1.5rem'}
                  themeStyles={getThemeStyles(
                    ['button', 'cancel-1'],
                    themeGlobal,
                  )}
                  onClick={() => cancelButtonClicked()}
                >
                  {props.t('generic.cancel')}
                </StyledButton>

                <S.SaveButtonContainer
                  id={'influencer-profile-save-button-disabled'}
                >
                  <StyledButton
                    borderRadius={'10rem'}
                    paddingLeft={'2rem'}
                    paddingRight={'2rem'}
                    paddingTop={'1rem'}
                    paddingBottom={'1rem'}
                    onClick={() => saveButtonClicked()}
                    disabled={!isSaveButtonEnabled()}
                  >
                    {props.t('generic.save')}
                  </StyledButton>
                </S.SaveButtonContainer>

                {!isSaveButtonEnabled() && (
                  <Tooltip
                    target={'influencer-profile-save-button-disabled'}
                    marginBottom={'0.5rem'}
                  >
                    {props.t(
                      'influencerProfile.save-button-disabled-tooltip-influencer',
                    )}
                  </Tooltip>
                )}
              </S.BusinessProfileFooter>
            </S.CardInnerContainer>
          </S.BusinessProfileCard>
        </S.InnerContainer>
      </S.Container>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={showInfluencerProfileUpdateSuccessNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {getActionResultTooltipMessage()}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.OuterContainer>
  );
};

export default withTranslation()(InfluencerProfileEdit);
