/* eslint-disable consistent-return */
import { UncontrolledTooltip } from 'reactstrap';
import styled, { css } from 'styled-components';

import {
  ArrowContainerProps,
  GearIconContainerProps,
  MainSectionsOuterMostContainerProps,
  PostTypeButtonProps,
  SocialAccountFilterContainerProps,
  TimeFrameContainerProps,
  TimeFramePickerContainerProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 80%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1200px) {
    width: 98%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh - 5.7rem - 4.4rem);
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 10rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  text-align: center;
  margin-bottom: 0.7rem;
`;

export const HeaderSubtitleContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: #676767;
  text-align: center;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const CardInnerContainer = styled.div`
  padding: 0rem 3.2rem 0 3.2rem;
  display: flex;
  align-items: flex-start;
  height: calc(100% - 20.5rem);
  flex-wrap: wrap;

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const MainSectionsOuterMostContainer = styled.div<MainSectionsOuterMostContainerProps>`
  width: 100%;
  height: 100%;
  visibility: ${props => (props.visible ? 'all' : 'hidden')};
`;

export const MainSectionsOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

export const MainSectionsContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 3rem;
  column-gap: 5rem;
  padding-top: 0.3rem;

  @media (max-width: 1200px) {
    column-gap: 24rem;
  }

  @media (max-width: 900px) {
    column-gap: 0;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: end;
  padding: 2rem 3.2rem;
  border-top: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
  margin-top: auto;
`;

export const SocialAccountContainer = styled.div`
  height: 6.9rem;
  width: 23rem;
  display: flex;
  border-radius: 1.5rem;
  padding: 0.5rem;

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const SocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const SocialAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const SocialAccountRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 0.8rem;
`;

export const SocialAccountUsernameContainer = styled.div`
  display: flex;
`;

export const SocialAccountUsername = styled.div`
  padding-bottom: 0.9rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const SocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
  height: 1.4rem;
  user-select: none;
  max-width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SocialAccountUsernameTooltip = styled(UncontrolledTooltip)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0.5rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const SocialAccountFilterContainer = styled.span<SocialAccountFilterContainerProps>`
  width: 40rem;

  ${props => {
    if (props.editMode) {
      return css`
        & * {
          pointer-events: none;
        }

        & div.comboBoxDropdownArrowContainerIdentifierClass svg {
          visibility: hidden;
        }
      `;
    }
  }}
`;

export const SocialAccountFilterTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const TimeFrameContainer = styled.div<TimeFrameContainerProps>`
  width: 40rem;

  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;

        & > div:nth-child(2)::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #dddddd;
          pointer-events: none;
          opacity: 0.2;
        }
      `;
    }
  }}
`;

export const TimeFrameInnerContainer = styled.div`
  height: 8.3rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: rgb(244, 244, 244);
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  padding: 0 0.8rem;
  position: relative;
`;

export const TimeFramePickerContainer = styled.div<TimeFramePickerContainerProps>`
  padding-top: 1.3rem;

  & .rdrStaticRanges,
  & .rdrInputRanges,
  & .rdrDefinedRangesWrapper {
    display: none;
  }

  & .rdrDateDisplayWrapper {
    background-color: white;
  }

  & .rdrMonths.rdrMonthsVertical,
  & .rdrMonthAndYearWrapper {
    pointer-event: none;
    opacity: 0;
    z-index: -9999;
    height: 0;
    transition: height 1s ease !important;
  }

  ${props => {
    if (props.visible) {
      return css`
        & .rdrMonths.rdrMonthsVertical,
        & .rdrMonthAndYearWrapper {
          pointer-event: all;
          opacity: 1;
          z-index: 9999;
          padding-top: 1rem;
          height: auto;
          background-color: #fff;
        }
      `;
    }
  }}
`;

export const ArrowContainer = styled.div<ArrowContainerProps>`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin: 0;
  margin-left: auto;
  height: 100%;
  cursor: pointer;
  color: #bfbfbf;
  &: hover {
    color: #8492a6;
  }

  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
      `;
    }
  }}
`;

export const ExpirationDateContainer = styled.div`
  width: 40rem;
`;

export const ExpirationDateInnerContainer = styled.div`
  height: 8.3rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: rgb(244, 244, 244);
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  position: relative;
`;

export const ExpirationDateLabel = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  color: #7d888d;
  margin-left: 0.5rem;
`;

export const ExpirationDatePickerContainer = styled.div`
  position: absolute;
  top: 79%;
  left: 0;
  background-color: #fff;
  z-index: 99999;

  & .rdrMonthAndYearWrapper {
    margin-top: -3rem;
    background-color: #fff;
  }
`;

export const AutomaticUpdateInnerContainer = styled.div`
  height: 8.3rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: rgb(244, 244, 244);
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  padding: 0 0.8rem;
  align-items: center;

  & input {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.3rem;
    color: #7d888d;
  }
`;

export const AutomaticUpdateLabel = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.3rem;
  color: #7d888d;
  margin-left: 0.5rem;
`;

export const GearIconContainer = styled.div<GearIconContainerProps>`
  cursor: pointer;

  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
      `;
    }
  }}
`;

export const FilterContentPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const FilterContentOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 26.5rem;
  padding: 0 2rem;

  & button {
    border-radius: 0.6rem;
    border-width: 0rem;
    height: 4.4rem;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 500;
    padding-right: 1rem;
    width: 17rem;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const KeywordsMainSectionContainer = styled.div`
  width: calc(100% - 26.5rem);
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;

  @media (max-width: 750px) {
    padding-top: 2rem;
    width: 100%;
  }
`;

export const KeywordsInnerSectionContainer = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  height: 100%;
`;

export const KeywordsSectionFirstRow = styled.div`
  display: flex;
  align-items: start;
  width: 100%;

  & > div:first-child {
    width: calc(100% - 6.1rem);
  }

  & button {
    border-radius: 0.6rem;
    border-width: 0rem;
    height: 100%;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 500;
    padding-right: 1rem;
    margin-left: 1rem;
  }
`;

export const SelectedKeywordsContainer = styled.div`
  padding: 0 0.4rem;
  margin-top: 3rem;
  height: calc(100% - 7.4rem);
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
`;

export const SelectedKeywordsInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  gap: 2rem;
  height: auto;
  width: auto;
`;

export const SelectedKeyword = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid #bfbfbf;
  padding: 1rem 1.3rem;
  border-radius: 2.8rem;
`;

export const SelectedKeywordText = styled.div`
  color: #676767;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-right: 0.6rem;
`;

export const SelectedKeywordCross = styled.div`
  cursor: pointer;

  &:hover svg path {
    fill: #687080;
  }
`;

export const FilterContentOptionsRowsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 655px) {
    flex-direction: column;
    align-items: center;
    & > div:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const FilterContentOptionsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & button {
    width: 16.5rem;
  }

  & svg path {
    fill: #14b56f;
  }

  @media (max-width: 750px) {
    width: 21.4rem;
    margin-bottom: 0;
  }

  @media (max-width: 655px) {
    position: relative;
    width: auto !important;
  }
`;

export const PostsMainSectionContainer = styled.div`
  display: flex;
  flex-grow: 0;
  width: calc(100% - 26.5rem);
  padding: 0 1rem;
  height: 100%;
  align-items: start;

  @media (max-width: 750px) {
    width: 100%;
    height: calc(100% - 10rem);
    overflow-y: auto;
    padding-top: 1rem;
  }

  @media (max-width: 635px) {
    height: calc(100% - 21rem);
  }
`;

export const PostsOptionsContainer = styled.div`
  height: auto;
  width: auto;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 750px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    & > button:last-of-type {
      margin-right: 4.3rem;
    }
  }

  @media (max-width: 655px) {
    flex-direction: column;
    align-items: center;

    & > button:last-of-type {
      margin-right: 0;
    }

    & > div,
    & > button {
      margin-bottom: 1rem;
    }

    & > div:first-of-type {
      margin-top: 1rem;
    }
  }
`;

export const PostsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  overflow-y: auto;
`;

export const PostsInnerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  height: auto;
  flex-wrap: wrap;
  width: auto;
  flex-grow: 0;
  position: relative;

  @media (max-width: 750px) {
    justify-content: center;
  }
`;

export const PostTypeButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 0.5rem;

  & div {
    margin-bottom: 1.2rem;
  }

  @media (max-width: 750px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    & > div {
      margin-bottom: 0;
    }
  }
`;

export const PostTypeButton = styled.div<PostTypeButtonProps>`
  background-color: #ffffff;
  font-size: 1.6rem;
  font-weight: 400;
  color: #676767;
  line-height: 1.9rem;
  cursor: pointer;
  border: 0.1rem solid #ccc;
  border-radius: 2rem;
  padding: 0.7rem 1.5rem;
  display: flex;
  justify-content: center;

  ${props => {
    if (props.active) {
      return css`
        background-color: #292929;
        color: #fff;
        border-color: #292929;
      `;
    }
  }}
`;

export const LoadingPostDataSpinnerContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingPostDataMessage = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #676767;
`;

export const MediaKitEditionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`;

export const MediaKitEditionMessageInner = styled.div`
  background-color: #e0e5ea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: #666f7c;
  padding: 0.8rem 1.4rem;
  border-radius: 1rem;
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const CheckMarkIconContainer = styled.div`
  @media (max-width: 655px) {
    position: absolute;
    top: 0;
    left: 100%;
  }
`;
