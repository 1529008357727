/* eslint-disable no-use-before-define */
import { IFile } from '../../components/shared/FilePickerV2/types';
import { Brand } from '../../Services/SkorrApi/new/Generic/types';
import { SocialNetworks } from '../../Services/Utils/types';

export interface ICreatePortfolioConfigurationProps {
  t: (path: string) => string;
}

export type AddMediaKitButtonContainerProps = {
  mediaKitsPanelVisible: boolean;
};

export type AddMediaKitPanelInnerContainerProps = {
  mediaKitCount: number;
};

export type AddedMediaKitsInnerContainerProps = {
  mediaKitCount: number;
};

export type MediaKitCustomizableView = {
  [mediaKitId: string]:
    | {
        name: string;
        displayPictureFile: IFile | undefined;
        showPicture: boolean;
        showIcon: boolean;
        showTitle: boolean;
        showUsername: boolean;
      }
    | undefined;
};

export type SocialAccountUsernameProps = {
  single?: boolean;
};

export type SocialAccountSubTextContainerProps = {
  single?: boolean;
};

export type SocialAccountRightContainerProps = {
  single?: boolean;
};

export type SocialAccountNetworkIconContainerProps = {
  single?: boolean;
};

export type ExpirationDateArrowContainerProps = {
  disabled?: boolean;
};

export enum PortfolioLayoutTypes {
  'HORIZONTAL_LAYOUT' = 'HORIZONTAL_LAYOUT',
  'VERTICAL_LAYOUT' = 'VERTICAL_LAYOUT',
}

export type PortfolioSettings = {
  profilePicturefile: IFile | null;
  backgroundPicturefile: IFile | null;
  themeColor: string;
  buttonUIColor: string;
  layout: PortfolioLayoutTypes;
  portfolioAuthorName: string;
  tags: string[];
  countryId: { value: string | undefined } | null;
  sectionTitle: string;
  sectionDescription: string;
  partnersSectionTitle: string;
  partners: (Brand | CustomPartnerCreated)[];
};

export type PortfolioLayoutTypeOptionProps = {
  active: boolean;
};

export type CustomizePortfolioColorProps = {
  white: boolean;
  color: string;
  active: boolean;
};

export type PortfolioColorActiveCheckMarkProps = {
  activeColor: string;
};

export type CustomizePortfolioHeaderColoredProps = {
  layoutType: PortfolioLayoutTypes;
  themeColor: string;
  backgroundCoverImage: any;
};

export type CustomizePortfolioTitleProps = {
  defaultThemeColor: boolean;
  backgroundCoverImageSelected: boolean;
};

export type PortfolioPartnerImageProps = {
  clickable: boolean;
};

export type CustomPartnerCreated = {
  name: string;
  website: string;
  url: string;
};

export type PortfolioPartnerSelectedProps = {
  cursor: string;
};

export type GeneratePortfolioUrlCustomHookParams = {
  name: string;
  expiredAt?: number;
  picture?: IFile;
  pictureBackground?: IFile;
  mediaKits: GeneratePortfolioUrlCustomHookMediaKit[];
  layout: PortfolioLayoutTypes;
  themeColor: string;
  buttonUIColor: string;
  contextName: string;
  tags: string[];
  countryId?: string;
  about?: { title?: string; content?: string };
  partnersTitle?: string;
  partners: { name: string; image: string }[];
};

export type GeneratePortfolioUrlCustomHookMediaKit = {
  id: string;
  showPicture: boolean;
  picture: IFile | string;
  showIcon: boolean;
  showTitle: boolean;
  title: string;
  showSubtitle: boolean;
  createdAt: string;
  socialNetwork: SocialNetworks;
  targetId: string;
  username: string;
};

export type MainSectionContainerProps = {
  hide: boolean;
};

export const portfolioColorValues = {
  white: '#FFFFFF',
  skorr: '#5870F6',
  red: '#B12B2C',
  mustard: '#DFB12D',
  green: '#54AB7B',
  purple: '#74167A',
  orange: '#EC5528',
  orangeRed: '#E53F3A',
  salmon: '#DE716E',
  redOrange: '#EC6053',
  coral: '#ED7D4F',
  mocha: '#B29284',
  sepia: '#876448',
  coffee: '#6B4F44',
  chocolate: '#3D220F',
  cocoa: '#693817',
  spice: '#D4B57C',
  sun: '#F1B012',
  lightKhaki: '#9F9D76',
  khaki: '#7A794D',
  lightOlive: '#88AA53',
  caribeGreen: '#61D2A6',
  darkAqua: '#4B7867',
  hunter: '#264728',
  tropical: '#3E7C6E',
  turquo: '#4C9298',
  robinBlue: '#68C6CA',
  tealBlue: '#51A2B3',
  steelBlue: '#2C5E75',
  skyBlue: '#7EA3B3',
  dodgerBlue: '#468DFA',
  blueGray: '#7298C7',
  blue: '#2A3F5C',
  blueBell: '#7F8A9C',
  denim: '#3D6CBE',
  violetBlue: '#3748AF',
  stateBlue: '#323363',
  blueViolet: '#7266B8',
  royalPurple: '#7254A3',
  indigo: '#44077B',
  vividViolet: '#865499',
  lightPlum: '#814782',
  orchid: '#CC75D6',
  plum: '#5F3864',
  thistle: '#A68BAA',
  lavander: '#BA8DA4',
  lightOrchid: '#D097B8',
  mulberry: '#B65389',
  wildStraw: '#E754A2',
  mediumViolet: '#B92D84',
  violetRed: '#934366',
  gumPink: '#E97AA5',
  ticklePink: '#EF8CA9',
  pink: '#DF7C91',
  darkPink: '#E54571',
  hotPink: '#D93265',
  radicalRed: '#ED566D',
  crimson: '#CC2F42',
  gray: '#4D4D4D',
  lightGray: '#999999',
};
