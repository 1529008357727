/* eslint-disable no-use-before-define */
import { PortfolioLayoutTypes } from '../../../../pages/CreatePortfolioConfiguration/types';
import { SocialNetworks } from '../../../Utils/types';
import { FileSourceEnum } from '../Files/types';

export type ExternalMediaKitConfiguration = {
  id: string;
  type?: ExternalMediaKitConfigurationTypes;
  name?: string;
  targetId?: string;
  displayPicture?: string;
  agentId?: string;
  userId?: string;
  expiredAt?: Date | null;
  socialNetwork?: SocialNetworks;
  insights?: {
    views: number;
  };
  userMkImages?: string[];
  filters?: {
    keywords?: string[] | null;
    includePostIds?: string[] | null;
    excludePostIds?: string[] | null;
  };
  details?: {
    startAt?: Date;
    endAt?: Date | null;
    picture?: string;
    kpis?: {
      audience?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      engagementRate?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      likes?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      comments?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      shares?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      impressions?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      reach?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
      posts?: {
        show?: boolean;
        avg?: boolean;
        total?: boolean;
        growth?: boolean;
      };
    };
    posts?: {
      show?: boolean;
      limit?: number;
      order?: PostsOrderByCriteria;
    };
    demographicsCountry?: {
      show?: boolean;
    };
    demographicsCity?: {
      show?: boolean;
    };
    genderAgeSplit?: {
      show?: boolean;
    };
    biography?: {
      show?: boolean;
    };
    hashtags?: {
      show?: boolean;
    };
    mediaKits?: { picture?: string }[];
  };
  createdAt?: Date;
  updatedAt?: Date;
  url?: string;
};

export type GetExternalMediaKitConfigurationsByUserIdResponse =
  ExternalMediaKitConfiguration[];

export enum ExternalMediaKitConfigurationTypes {
  EMK_PUBLIC_USER = 'EMK_PUBLIC_USER',
  EMK_SKORR_USER = 'EMK_SKORR_USER',
  EMK_PORTFOLIO = 'EMK_PORTFOLIO',
}

export enum PostsOrderByCriteria {
  DATE = 'DATE',
}

export type GenerateExternalMediaKitUrlParams = {
  type: ExternalMediaKitConfigurationTypes;
  name: string;
  targetId: string;
  expiredAt: number | null;
  socialNetwork: SocialNetworks;
  startAt: number;
  endAt: number | null;
  filters: {
    keywords: string[] | null;
    includePostIds: string[] | null;
    excludePostIds: string[] | null;
  };
};

export type GenerateExternalMediaKitUrlResponse = {
  url: string;
  mediaKitId: string;
};

export type UpdateExternalMediaKitByIdParams = {
  id: string;
  name: string;
  expiredAt: number | null;
  startAt: number;
  endAt: number | null;
  filters: {
    keywords: string[] | null;
    includePostIds: string[] | null;
    excludePostIds: string[] | null;
  };
};

export type UpdateExternalMediaKitByIdResponse = {
  updated: boolean;
};

export type GeneratePortfolioUrlParams = {
  id?: string;
  name: string;
  expiredAt?: number;
  picture?: string;
  pictureBackground?: string;
  mediaKits: PortfolioMediaKit[];
  layout: PortfolioLayoutTypes;
  themeColor: string;
  buttonUIColor: string;
  contextName: string;
  tags: string[];
  countryId?: string;
  about?: { title?: string; content?: string };
  partnerstitle?: string;
  partners: { name: string; image: string }[];
};

export type PortfolioMediaKit = {
  id: string;
  showPicture: boolean;
  picture: string;
  showIcon: boolean;
  showTitle: boolean;
  title: string;
  showSubtitle: boolean;
  createdAt: string;
  socialNetwork: SocialNetworks;
  targetId: string;
  username: string;
};

export type GeneratePortfolioUrlResponse = {
  url: string;
  mediaKitId: string;
};

export type GetPortfolioByBrandPictureInput = {
  brandPicture: string;
  limit?: number;
};

export type GetPortfolioByBrandPictureOutput = {
  portfolios: { id: string; name: string; image: string; createdAt: Date }[];
  total: number;
};

export type UploadPortfolioImageInput = FormData;

export type UploadPortfolioImageOutput = {
  url: string;
  id: string;
};

export type UploadUserBrandPictureParams = FormData;

export type DeleteUserBrandPictureParams = {
  url: string[];
};

export type DeleteUserBrandPictureResponse = {
  success: true;
};

export type GetUserBrandPicturesResponse = {
  name: string;
  url: string;
  website?: string;
}[];
