/* eslint-disable consistent-return */
import styled, { keyframes, css } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';

import {
  IContainerProps,
  IOuterContainerProps,
  ISpinnerProps,
  IMessageContainerProps,
  ISpinnerContainerProps,
} from './types';

export const OuterContainer = styled.div<IOuterContainerProps>`
  position: relative;

  display: grid;
  ${props => {
    if (props.borderRadius) {
      return css`
        border-radius: ${props.borderRadius};
      `;
    }
  }};
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.backgroundColor ??
    props.theme.loaderLocal['container-background-color']};
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props => {
    if (props.borderRadius) {
      return css`
        border-radius: ${props.borderRadius};
      `;
    }
  }};
`;

export const MessageContainer = styled.div<IMessageContainerProps>`
  color: ${props => props.theme.loaderLocal['message-color']};
  font-size: ${props => props.fontSize ?? '1.6rem'};
  user-select: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(ImSpinner2)<ISpinnerProps>`
  animation: ${rotation} 2s linear infinite;
  font-size: ${props => props.fontSize ?? '2.4rem'};
`;

export const SpinnerContainer = styled.div<ISpinnerContainerProps>`
  display: inline-flex;
  margin-top: ${props => props.marginTop ?? '0rem'};
  margin-bottom: ${props => props.marginBottom ?? '0rem'};
  margin-left: ${props => props.marginLeft ?? '0rem'};
  margin-right: ${props => props.marginRight ?? '0rem'};
  padding-top: ${props => props.paddingTop ?? '0rem'};
  padding-bottom: ${props => props.paddingBottom ?? '0rem'};
  padding-left: ${props => props.paddingLeft ?? '0.5rem'};
  padding-right: ${props => props.paddingRight ?? '0rem'};
  align-items: center;
`;
